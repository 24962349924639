import React, { useEffect } from 'react'
import { Switch, useHistory } from 'react-router-dom'
import './App.css'
import RootRouter from './RootRouter'
import { apiRequestUtils } from './utils/apiRequestUtils'
import Spinner from './pages/components/Spinner'
import { useAuth0 } from '@auth0/auth0-react'
import ProtectedRoute from './auth/ProtectedRoute'
import { getBaseRole } from './utils/roleManager'
import { roleTypes } from './frontendConsts'
import './modal-video.scss'
import 'scorm-again'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthorizationThunk } from './store/AuthSlice'
import { phinTheme } from './shared/components/MuiOverrideThemes'
import TopNavBar from './pages/TopNavBar'
import SideNav from './pages/components/SideNav'
import { ThemeProvider } from '@mui/material/styles'
import { Box } from '@mui/material'
import { FreeTrialBanner } from './shared/components/FreeTrialBanner'
import ScrollToTop from './pages/components/ScrollToTop'
import { Pax8NoticeBanner } from './pages/pax8/Pax8NoticeBanner'

window.API = new window.Scorm12API({ })

function App () {
  const { isLoading, isAuthenticated, user, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0()
  const { authorization } = useSelector((state) => state.auth)

  let id
  if (authorization) {
    const [routeNothing, routeRoleType, routeRoleId, ...pages] = window.location.pathname.split('/')
    const { roles } = authorization
    if (roles[routeRoleType] && roles[routeRoleType][routeRoleId] !== undefined) {
      id = routeRoleId
    }
  }

  // The CC component needs this to be initialized
  window.Chargebee.init({
    site: process.env.REACT_APP_CHARGEBEE_SITE_NAME,
    publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY
  })

  // user.sub is the User's unique ID

  const history = useHistory()
  const dispatch = useDispatch()

  const configureAuthenticationAndAuthorization = async () => {
    const accessToken = await getAccessTokenSilently()
    apiRequestUtils.setAccessToken(accessToken)
    apiRequestUtils.setLoginWithRedirect(loginWithRedirect)
    try {
      const authorization = await dispatch(getAuthorizationThunk(user.sub))
      return authorization
    } catch (error) {
      console.error(error)
    }
  }

  const smartRoute = (authorization) => {
    const baseRole = getBaseRole(authorization)
    if (baseRole === null) {
      logout({ returnTo: window.location.origin })
    } else {
      if (baseRole.roleType === roleTypes.COMPANY) {
        history.push(`/companies/${baseRole.id}`)
      } else if (baseRole.roleType === roleTypes.PARTNER) {
        history.push(`/partners/${baseRole.id}`)
      } else if (baseRole.roleType === roleTypes.DISTRIBUTOR) {
        history.push(`/distributors/${baseRole.id}`)
      }
    }
  }

  const checkForPageAccess = (pathName, authorization) => {
    const excludedRoutes = ['/integrations', '/integrations/googleUserSync']
    if (!excludedRoutes.includes(pathName)) {
      const [routeNothing, routeRoleType, routeRoleId, ...pages] = pathName.split('/')
      const { roles } = authorization
      try {
        if (roles[routeRoleType][routeRoleId] !== undefined) {
          return true
        }
      } catch (err) {
        return false
      }
    } else {
      return true
    }
  }

  function getOrganizationInfoFromAuthSlice (authorization) {
    const roles = authorization.roles

    // Function to find the organization type and id based on priority
    const getOrganizationDetails = (roleType) => {
      if (roles[roleType]) {
        for (const id in roles[roleType]) {
          if (roles[roleType][id] === 'admin') {
            return { OrganizationType: roleType.slice(0, -1), OrganizationId: id } // Remove the trailing 's'
          }
        }
      }

      return null
    }

    let orgDetails = getOrganizationDetails('distributors')
    if (orgDetails) { return orgDetails }

    orgDetails = getOrganizationDetails('partners')
    if (orgDetails) { return orgDetails }

    orgDetails = getOrganizationDetails('companies')
    if (orgDetails) { return orgDetails }

    return { OrganizationType: null, OrganizationId: null }
  }

  function initializePendo (user, authorization) {
    (function (apiKey) {
      (function (p, e, n, d, o) {
        let w, x
        o = p[d] = p[d] || {}
        o._q = o._q || []

        const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']

        for (w = 0, x = v.length; w < x; ++w) {
          (function (m) {
            o[m] = o[m] || function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)))
            }
          })(v[w])
        }

        const y = e.createElement(n)
        y.async = !0
        y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`

        const z = e.getElementsByTagName(n)[0]
        z.parentNode.insertBefore(y, z)
      })(window, document, 'script', 'pendo')
    })(process.env.REACT_APP_PENDO_API_ID)

    if (window.pendo) {
      const organizationInfo = getOrganizationInfoFromAuthSlice(authorization)
      window.pendo.initialize({
        visitor: {
          id: user.sub, // Required if user is logged in
          email: user.email,
          name: user.name
          // ... other visitor data for pendo can go here if needed
        },
        account: {
          id: user.sub,
          name: user.name,
          organizationType: organizationInfo.OrganizationType,
          organizationId: organizationInfo.OrganizationId
          // ... other account data for pendo can go here if needed
        }
      })

      window.pendo.debugging = true
      if (typeof window.pendo.validateInstall === 'function') {
        // validation logging for pendo
        window.pendo.validateInstall()
        window.pendo.validateEnvironment()
      }
    }
  }

  function configureHotJar (h, o, t, j, a, r) {
    // Install HotJar
    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) }
    h._hjSettings = { hjid: process.env.REACT_APP_HOTJAR_ID, hjsv: 6 }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script'); r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)

    // Replace your_user_id with your own if available.
    const userId = user.sub || null

    window.hj('identify', userId, {})
  }

  const callConfigure = async () => {
    const authorization = await configureAuthenticationAndAuthorization()

    const canAccessCurrentPath = checkForPageAccess(window.location.pathname, authorization)

    if (process.env.REACT_APP_HOTJAR_ID) {
      configureHotJar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    }

    if (window.location.pathname === '/' || !canAccessCurrentPath) {
      smartRoute(authorization)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      callConfigure()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (process.env.REACT_APP_PENDO_API_ID && authorization && user) {
      initializePendo(user, authorization)
    }
  }, [authorization, user])

  if (isLoading) {
    return (
      <Spinner message='Authenticating' />
    )
  }

  return (
    <ThemeProvider theme={phinTheme}>
      <Box sx={{ display: 'flex' }}>
        <FreeTrialBanner />
        <Pax8NoticeBanner />
        <TopNavBar />
        <SideNav
          id={id}
        />
        <Box
          component='main' sx={{ flexGrow: 1, p: 3 }}
        >
          <ScrollToTop />
          <Switch>
            <ProtectedRoute
              path='/'
              component={RootRouter}
            />
          </Switch>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default App
