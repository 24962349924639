import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'
import PartnersPage from '../pages/PartnersPage'
import { useDispatch, useSelector } from 'react-redux'
import { getPartnerAndSetNavThunk } from '../store/PartnerSlice'
import { getLogoLocationThunk } from '../store/LogoSlice'
import { useAuth0 } from '@auth0/auth0-react'
import PartnerBrandingPage from '../pages/PartnerBrandingPage'
import { PartnerIntegrationsPage } from '../pages/PartnerIntegrationsPage'
import { PhinApiIntegrationPage } from '../pages/PhinApiIntegrationPage'
import { GradientIntegrationPage } from '../pages/GradientIntegrationPage'
import { PartnerBillingPage } from '../pages/partnerPages/PartnerBillingPage'
import { PartnerManageBillingPlanPage } from '../pages/partnerPages/PartnerManageBillingPlanPage'
import { setSideNavOrgDataAction } from '../store/SideNavSlice'
import { setTopNavPartnerDataAction } from '../store/TopNavSlice'
import { orgTypes, PAX8_ACCOUNT_STATUSES, PHIN_DISTRIBUTOR_ID } from '../frontendConsts'
import { smartRoute } from '../utils/RouterUtils'
import { setPartnerBillingDetailsAction, setPartnerBillingInvoicesAction, setPartnerBillingReportsAction } from '../store/PartnerBillingSlice'
import { ConnectwiseBillingIntegrationPage } from '../pages/components/ConnectwiseBillingIntegrationPage'
import { Pax8PlanPickerPage } from '../pages/pax8/Pax8PlanPickerPage'
import { ConnectwiseServiceDeskIntegrationSetup } from '../pages/components/ConnectwiseServiceDeskIntegrationSetup'
import { PartnerTriagingPage } from '../pages/partnerPages/PartnerTriagingPage'
import { ReportedEmailTriagePage } from '../pages/partnerPages/components/triage/ReportedEmailTriagePage'
import { startPax8FreeTrialThunk } from '../store/Pax8PartnerSlice'
import PartnerSettingsPage from '../pages/PartnerSettingsPage'

export function PartnerRouter ({ id }) {
  const { path } = useRouteMatch()
  const { partner } = useSelector((state) => state.partner)
  const { logoUrl } = useSelector((state) => state.logo)
  const { authorization } = useSelector((state) => state.auth)
  const { isLoadingStartPax8FreeTrial } = useSelector((state) => state.pax8Partner)

  const dispatch = useDispatch()
  const { logout } = useAuth0()
  const history = useHistory()

  async function setup () {
    try {
      const partner = await dispatch(getPartnerAndSetNavThunk(id))

      // Check partner admins array and see if logged in user is in there. Only start trial if they are in there

      const { admins } = partner
      const isAdminInPartner = admins.some(admin => admin.email === authorization.email)

      if (partner.distributorId === process.env.REACT_APP_PAX8_DISTRIBUTOR_ID &&
        partner.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.AWAITING_FREE_TRIAL_START &&
      !isLoadingStartPax8FreeTrial && isAdminInPartner) {
        dispatch(startPax8FreeTrialThunk({ partnerId: id }))
      }

      dispatch(setPartnerBillingReportsAction(null))
      dispatch(setPartnerBillingDetailsAction(null))
      dispatch(setPartnerBillingInvoicesAction(null))

      if (partner && (!partner.freeTrial || partner.automaticBillingEnabled)) {
        dispatch(getLogoLocationThunk({ partnerId: id }))
      }
    } catch (error) {
      console.error(error)
      smartRoute({ authorization, history, logout })
    }
  }

  useEffect(() => {
    if (id && partner?.id !== id) {
      setup()
    } else if (partner) {
      const { name, isFreeTrial, distributorId, distributorName } = partner
      dispatch(setSideNavOrgDataAction({ name, id: partner.id, isFreeTrial, orgType: orgTypes.PARTNER }))
      dispatch(setTopNavPartnerDataAction({ name, id: partner.id, distributorId, distributorName }))
      if (!logoUrl || (logoUrl && !logoUrl.includes(partner.id))) {
        dispatch(getLogoLocationThunk({ partnerId: partner.id }))
      }
    }
  }, [])

  return (
    <div style={(partner?.freeTrial || partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.TRIALING || partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.AWAITING_FREE_TRIAL_START)
      ? {
          margin: '8rem 3% 2rem',
          height: '82vh'
        }
      : {
          margin: '5rem 3% 2rem',
          height: '88vh'
        }}
    >
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <PartnersPage
              id={id}
            />
          )}
        />
        <Route
          exact
          path={`${path}/branding`}
          render={() => (
            <PartnerBrandingPage
              id={id}
            />
          )}
        />
        <Route
          exact
          path={`${path}/integrations`}
          render={() => (<PartnerIntegrationsPage id={id} />)}
        />

        {(partner && (partner.distributorId === PHIN_DISTRIBUTOR_ID || partner.distributorId === process.env.REACT_APP_PAX8_DISTRIBUTOR_ID)) &&
          <Route
            exact
            path={`${path}/billing`}
            render={() => (<PartnerBillingPage partnerId={id} />)}
          />}

        {(partner && partner.distributorId === process.env.REACT_APP_PAX8_DISTRIBUTOR_ID) && (
          <>
            <Route
              exact
              path={`${path}/pax8-pricing`}
              render={() => (<Pax8PlanPickerPage partnerId={id} />)}
            />
            <Route
              exact
              path={`${path}/settings`}
              render={() => (<PartnerSettingsPage partnerId={id} />)}
            />
          </>
        )}

        {(partner && partner.distributorId === PHIN_DISTRIBUTOR_ID) &&
          <Route
            exact
            path={`${path}/triaging`}
            render={() => (<PartnerTriagingPage partnerId={id} />)}
          />}

        {(partner && partner.distributorId === '0Sky1I4aUzDXjgHqPjZk') &&
          <Route
            exact
            path={`${path}/triaging/:messageId`}
            render={() => (<ReportedEmailTriagePage partnerId={id} />)}
          />}

        {(partner && partner.distributorId === '0Sky1I4aUzDXjgHqPjZk') &&
          <Route
            exact
            path={`${path}/billing/manage`}
            render={() => (<PartnerManageBillingPlanPage partnerId={id} />)}
          />}

        <Route
          exact
          path={`${path}/integrations/phinApi`}
          render={() => (<PhinApiIntegrationPage id={id} />)}
        />
        <Route
          exact
          path={`${path}/integrations/gradientMSP`}
          render={() => (<GradientIntegrationPage id={id} />)}
        />

        <Route
          exact
          path={`${path}/integrations/connectWiseBilling`}
          render={() => (<ConnectwiseBillingIntegrationPage partnerId={id} />)}
        />
        <Route
          exact
          path={`${path}/integrations/connectWiseServiceDesk`}
          render={() => (<ConnectwiseServiceDeskIntegrationSetup partnerId={id} />)}
        />
      </Switch>
    </div>
  )
}
