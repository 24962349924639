import apiSlice from './apiSlice'

export const cwApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    connectServiceDeskIntegration: builder.mutation({
      query: ({ partnerId, cwPartnerId, publicKey, privateKey, cwApiUrl }) => ({
        url: `/partners/${partnerId}/integrations/cw-service-desk-api/connect`,
        body: { cwPartnerId, publicKey, privateKey, cwApiUrl },
        method: 'POST'
      })
    }),
    disconnectServiceDeskIntegration: builder.mutation({
      query: ({ partnerId }) => ({
        url: `/partners/${partnerId}/integrations/cw-service-desk-api/disconnect`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useConnectServiceDeskIntegrationMutation,
  useDisconnectServiceDeskIntegrationMutation
} = cwApi
