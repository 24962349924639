import { reverseMap } from './utils/objectUtils.js'

export const navigationRoutes = {
  CAMPAIGNS: '/phishing/campaigns',
  CAMPAIGNLAUNCHER: '/phishing/campaigns/launcher',
  CAMPAIGNEDITOR: '/phishing/campaigns/editor',
  CAMPAIGNCREATOR: '/phishing/campaigns/creator',
  CAMPAIGNPRESETS: '/phishing/campaigns/presets',
  TRAININGCREATOR: '/training/catalog/create',
  CURRICULUM_DASHBOARD: '/training',
  TRAINING_VIEWER: '/training/viewer',
  PHISHING_VIEWER: '/phishing/viewer',
  TRAINING_CURRICULUM_FORM: '/training/curriculum/editor',
  ANALYTICS_TRAINING: '/analytics/training',
  ANALYTICS_USERS: '/analytics/users',
  USERS: '/users',
  USERS_UPLOAD: '/users/upload'
}

export const phishTestHeaderText = {
  name: 'X-PHISHTEST',
  value: 'This is a phishing security test from Phin Security for your organization'
}

export const shareLevels = {
  PREMIUM: 2,
  PARTNER: 1,
  CLIENT: 0
}

export const campaignTypes = {
  FIXEDLENGTH: 'fixed length',
  CONTINUOUS: 'continuous',
  CONTINUOUS_TRAINING: 'continuous-training',
  TESTING: 'testing',
  TRAINING: 'training',
  PHISHING: 'phishing',
  ONBOARDING: 'onboarding',
  POLICY: 'policy'
}

export const sendingFrequencyEditLock = {
  BEFORESTART: 0,
  INPROGRESS: 1,
  AFTEREND: 2
}

export const roleTypes = {
  PARTNER: 'partners',
  COMPANY: 'companies',
  DISTRIBUTOR: 'distributors'
}

export const orgTypes = {
  PARTNER: 'partners',
  COMPANY: 'companies',
  DISTRIBUTOR: 'distributors'
}

export const orgLevels = {
  PARTNER: 'partner',
  COMPANY: 'company',
  DISTRIBUTOR: 'distributor',
  DEFAULT: 'default'
}

export const roles = {
  ADMIN: 'admin'
}

export const dateIntervals = {
  '7d': 7,
  '30d': 30,
  '90d': 90,
  '1y': 365,
  custom: 0
}

export const learningTypes = {
  LM: 'lm',
  VIDEO: 'video',
  CUSTOM: 'custom'
}

export const videos = {
  'The Social Engineer': 'phin-video-training/HTAC_S1_E03_r01_en_US_SocialEngineering.mp4',
  Phishing: 'phin-video-training/HTAC_S1_E06_r01_en_US_Phishing.mp4',
  'Incident Reporting': 'phin-video-training/HTAC_S2_E04_r01_en_US_IncidentReporting.mp4',
  'Social Engineering - Animated': 'phin-video-training/HTANC_S1_E02_r04_en_US_SocialEngineering [English].mp4',
  'Phishing - Animated': 'phin-video-training/HTANC_S1_E04_r04_v1_en_US_Phishing.mp4',
  'Incident Reporting - Animated': 'phin-video-training/HTANC_S1_E07_r01_en_US_Reporting.mp4',
  'Shorty: How Your Brain Tricks You - Animated': 'phin-video-training/HTANC_SHORTS_E02_V01_HOW YOUR BRAIN TRICKS YOU_English.mp4'
}

export const videoPaths = reverseMap(videos)

export const microsoftIntegrationStatuses = {
  NOT_CONNECTED: 'not connected',
  DISCONNECTED: 'disconnected',
  CONSENT_SUBMITTED: 'consent submitted',
  CONSENTED: 'consented',
  TEMP_STAGING: 'temp staging',
  TEMP_STAGED: 'temp staged',
  STAGING: 'staging',
  STAGED: 'staged',
  COMMITTING: 'committing',
  CONNECTED: 'connected'
}

export const distributorIntegrationStatuses = {
  NOT_ENABLED: 'not enabled',
  ENABLED: 'enabled'
}
export const partnerIntegrationStatuses = {
  NOT_ENABLED: 'not enabled',
  ENABLED: 'enabled'
}

export const integrationStatuses = {
  NOT_CONNECTED: 'not connected',
  CONNECTING: 'connecting',
  CONNECTED: 'connected'
}

export const operationTypes = {
  ADD: 'add',
  UPDATE_FROM_EXTERNAL_ID: 'updateFromExternalId',
  UPDATE_FROM_EMAIL: 'updateFromEmail',
  REMOVE: 'remove'
}

export const integrationTypes = {
  USER_SYNC: 'userSync',
  SPAM_FILTER_BYPASS: 'spamFilterBypass',
  LEGACY_SPAM_BYPASS: 'spamBypass',
  PHIN_API: 'phinAPI',
  GRADIENT: 'gradientMSP',
  REPORT_A_PHISH: 'reportAPhish',
  CW_BILLING: 'connectWiseBilling',
  CW_SERVICE_DESK: 'connectWiseServiceDesk',
  GOOGLE_USER_SYNC: 'googleUserSync',
  PROCESS_REPORTED_EMAILS: 'processReportedEmails',
  ALLOWLIST: 'allowlist'
}

export const RAP_FILTER_TYPES = {
  SENDER: 'sender'
}

export const userStatuses = {
  ACTIVE: 'active',
  SLEPT: 'slept',
  DELETED: 'deleted'
}

export const groupStatuses = {
  ACTIVE: 'active',
  SLEPT: 'slept'
}
export const syncStatuses = {
  SUCCESS: 'success',
  ERROR: 'error'
}

export const audienceSelectionTypes = {
  FIXED: 'fixed',
  DYNAMIC: 'dynamic'
}

export const audienceOperations = {
  ADD: 'add',
  REMOVE: 'remove'
}

export const sendEngineTypes = {
  SCALE_TEST: 'scaleTest',
  MANDRILL: 'mandrill',
  LEGACY_SPAM_BYPASS: 'spamBypass',
  SPAM_FILTER_BYPASS: 'spamFilterBypass'
}

export const trainingAnalyticsSelectionTypes = {
  COURSE_VIEW: 0,
  USER_VIEW: 1
}

export const phishingAnalyticsSelectionTypes = {
  USER_VIEW: 0,
  CLICK_VIEW: 1
}

export const campaignFrequencyIterations = {
  ONCE: 'Once',
  TWICE: 'Twice',
  ALL_AT_ONCE: 'All At Once'
}

export const CURRICULUM_BUILD_TYPES = {
  PREBUILT: 'prebuilt',
  MANUAL: 'manual',
  TOPICS: 'topics'
}

export const AUDIENCE_FILTER_TYPES = {
  ALL_COMPANY: 'first',
  DEPARTMENT_NAME: 'deptname',
  SUPERVISOR_EMAIL: 'supvemail',
  GROUPS: 'group'
}

export const LESSON_STATUS = {
  PASSED: 'passed'
}

export const WEEK_DAYS = {
  0: 'Sundays',
  1: 'Mondays',
  2: 'Tuesdays',
  3: 'Wednesdays',
  4: 'Thursdays',
  5: 'Fridays',
  6: 'Saturdays'
}

export const CAMPAIGN_SCHEDULE_PREVIEWER_DATE_DETERMINER = {
  1: '1st',
  2: '2nd',
  3: '3rd',
  4: '4th'
}

export const PHISHING_TEMPLATE_INJECTED_FIELDS = {
  EMPLOYEE_ID:
  {
    value: 'emplid',
    label: 'Employee ID'
  },
  FIRST_NAME:
  {
    value: 'first',
    label: 'First Name'
  },
  LAST_NAME:
  {
    value: 'last',
    label: 'Last Name'
  },
  EMAIL:
  {
    value: 'email',
    label: 'Email'
  },
  JOB_TITLE:
  {
    value: 'title',
    label: 'Job Title'
  },
  DEPARTMENT_ID:
  {
    value: 'deptid',
    label: 'Department ID'
  },
  DEPARTMENT_NAME:
  {
    value: 'deptname',
    label: 'Department Name'
  },
  SUPERVISOR_NAME:
  {
    value: 'supvname',
    label: 'Supervisor Name'
  },
  SUPERVISOR_EMAIL:
  {
    value: 'supvemail',
    label: 'Supervisor Email'
  },
  OFFICE_PHONE:
  {
    value: 'ophone',
    label: 'Office Phone Number'
  },
  MOBILE_PHONE:
  {
    value: 'phone',
    label: 'Mobile Phone'
  },
  COMPANY_NAME:
  {
    value: 'company_name',
    label: 'Company Name'
  },
  CURRENT_TIME:
  {
    value: 'current_time',
    label: 'Current Time'
  },
  CURRENT_SHORT_DATE:
  {
    value: 'current_short_date',
    label: 'Current Short Date'
  },
  CURRENT_HUGE_DATE:
  {
    value: 'current_huge_date',
    label: 'Current Huge Date'
  },
  PAST_TIME:
  {
    value: 'past_time',
    label: 'Past Time'
  },
  PAST_SHORT_DATE:
  {
    value: 'past_short_date',
    label: 'Past Short Date'
  },
  PAST_HUGE_DATE:
  {
    value: 'past_huge_date',
    label: 'Past Huge Date'
  },
  FUTURE_TIME:
  {
    value: 'future_time',
    label: 'Future Time'
  },
  FUTURE_SHORT_DATE:
  {
    value: 'future_short_date',
    label: 'Future Short Date'
  },
  FUTURE_HUGE_DATE:
  {
    value: 'future_huge_date',
    label: 'Future Huge Date'
  },
  MAIL_NICKNAME:
  {
    value: 'mailNickname',
    label: 'Mail Nickname'
  },
  SIGN_IN_SESSIONS_VALID_FROM_DATE_TIME:
  {
    value: 'signInSessionsValidFromDateTime',
    label: 'Sign In Sessions Valid From Date Time'
  },
  OFFICE_LOCATION:
  {
    value: 'officeLocation',
    label: 'Office Location'
  },
  EMPLOYEE_TYPE:
  {
    value: 'employeeType',
    label: 'Employee Type'
  },
  LAST_PASSWORD_CHANGE_DATE_TIME:
  {
    value: 'lastPasswordChangeDateTime',
    label: 'Last Password Change Date Time'
  },
  PREFERRED_LANGUAGE:
  {
    value: 'preferredLanguage',
    label: 'Preferred Language'
  }
}

export const PHISHING_DEFAULT_SEND_WINDOW = 3 // 3 Days

export const NOTIFICATIONS = {
  ALERT_SEVERITY: {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info'
  }
}

export const EMAIL_FOLDER_NAMES = {
  JUNK: 'junkemail',
  DELETED_ITEMS: 'deleteditems'
}

export const STYLE = {
  BUTTONS: {
    TERTIARY: {
      textDecoration: 'underline',
      textDecorationColor: 'rgba(57, 160, 255, .5)'
    }
  }
}

export const EMAIL_TRIAGE_TAGS = {
  FINANCIAL: 'financial',
  INVOICE: 'invoice',
  NEWSLETTER: 'newsletter',
  MARKETING: 'marketing',
  VENDOR: 'vendor',
  URGENCY: 'urgency',
  AUTHORITY: 'authority',
  AUTOMATED_MESSAGE: 'automatedMessage'
}

export const EMAIL_TRIAGE_SEVERITY = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high'
}

export const PHIN_DISTRIBUTOR_ID = '0Sky1I4aUzDXjgHqPjZk'

export const PAX8_ACCOUNT_STATUSES = {
  AWAITING_FREE_TRIAL_START: 'awaitingFreeTrialStart',
  TRIALING: 'trialing',
  ACTIVE: 'active',
  TRIAL_SUSPENDED: 'trialSuspended',
  SUSPENDED: 'suspended',
  PRORATE: 'prorate'
}
