import React from 'react'
import { MDBContainer } from 'mdb-react-ui-kit'

function Spinner ({ message, size, secondaryMessage, noExtraClasses, usePhinAnimation = false }) {
  return (
    <MDBContainer>
      <div className={noExtraClasses ? '' : 'd-flex align-items-center justify-content-center mt-5 mb-2'}>
        {usePhinAnimation
          ? (
            <lottie-player src='/phinLoaderAnimation.json' background='transparent' speed='1' style={{ width: '200px', height: '200px' }} loop autoplay />
            )
          : (
            <div
              className='spinner-border'
              style={{
                color: '#45cafc',
                width: size || '4rem',
                height: size || '4rem'
              }}
              role='status'
            />
            )}
      </div>
      {message && (
        <h2 className='d-flex align-items-center justify-content-center mt-4'>
          {message}
        </h2>)}
      {secondaryMessage && (
        <h4 className='d-flex align-items-center justify-content-center mt-4'>
          {secondaryMessage}
        </h4>
      )}
    </MDBContainer>
  )
}

export default Spinner
